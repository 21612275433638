














































































































































































































import { Component, Vue } from 'vue-property-decorator'
import ModalAddFolder from '@/components/Modal/ModalAddFolder.vue'
import ModalMoveToFolder from '@/components/Modal/ModalMoveToFolder.vue'
import ModalEditFolder from '@/components/Modal/ModalEditFolder.vue'
import ModalChangeOrder from '@/components/Modal/ModalChangeOrder.vue'
import GroupFolderService from '@/services/GroupFolderService'
import { EGroupAuth } from '@/models'

@Component({
  components: {
    ModalAddFolder,
    ModalMoveToFolder,
    ModalEditFolder,
    ModalChangeOrder
  }
})
export default class SettingOrder extends Vue {
  private list_folders_groups: any[] = []
  private groupsInFolder: any = { groups: [] }
  private folderId: number = 0
  private folderIndex: number = 0
  private selected_groups: any = []
  private defaultAvatar: any = require('@/assets/images/group/avatar_default.png')
  private list_folders: any[] = []
  private eGroupAuth = EGroupAuth
  private confirmChange: any
  private selected: string = ''

  // beforeRouteLeave(to: any, from: any, next: any) {
  //   //this case for modal search header
  //   if (document.querySelectorAll('#modal-search-header').length) {
  //     next()

  //     //normal case
  //   } else if (from.name !== to.name) {
  //     this.confirmChange = next
  //     this.$bvModal.show('modal-confirm')
  //   } else {
  //     next()
  //   }
  // }

  confirm() {
    this.confirmChange()
  }

  created() {
    this.getFoldersAndGroups()
  }

  getFoldersAndGroups() {
    GroupFolderService.getGroupAndFolderOfUser()
      .then(response => {
        if (response.status === 200) {
          this.list_folders_groups = response.data
          const data = this.list_folders_groups.filter(item => item.type === 1)
          this.list_folders = [
            {
              id: -1,
              name: this.$t('setting.display_order_groups.non_folder')
            },
            ...data
          ]
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  changeOrderFolder(selectedFolder: any) {
    this.groupsInFolder = selectedFolder
    this.$bvModal.show('folder-order')
  }

  submitChangeOrderFolder(returnList: any[]) {
    const groupIds = returnList.map(item => item.id)
    GroupFolderService.updateOrderFolder(
      this.groupsInFolder.id,
      groupIds
    ).finally(() => this.getFoldersAndGroups())
  }

  onSubmitAddFolder(folder_name: string) {
    let data = {
      name: folder_name
    }
    GroupFolderService.createFolderByUser(data)
      .then(response => {
        if (response.status === 200) {
          this.getFoldersAndGroups()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  onSubmitEditFolder(new_name: string) {
    let data = {
      name: new_name
    }
    GroupFolderService.editFolderByUser(this.folderId, data)
      .then(response => {
        if (response.status === 200) {
          this.getFoldersAndGroups()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  onDeleteEditFolder() {
    GroupFolderService.deleteFolderByUser(this.folderId)
      .then(response => {
        if (response.status === 200) {
          this.getFoldersAndGroups()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  showModalEditFolder(item: any, index: any) {
    this.folderIndex = index
    this.folderId = item.id
    this.$bvModal.show('edit-folder')
  }

  submitChangeOrder(returnList: any[]) {
    let newOrder = returnList.map(item => {
      return {
        id: item.id,
        type: item.type
      }
    })
    let data = newOrder
    GroupFolderService.updateOrder(data)
      .then(response => {
        if (response.status === 200) {
          this.getFoldersAndGroups()
        }
      })
      .catch(error => {
        this.getFoldersAndGroups()
      })
  }

  onSubmitMoveFolder(selectedFolder: any) {
    let selectedGroups = this.selected_groups.map((item: any) => {
      return item.id
    })

    let data: any = { group_ids: selectedGroups }
    if (selectedFolder.id !== -1) {
      data.folder_id = selectedFolder.id
    }

    GroupFolderService.moveGroupToFolder(data)
      .then(response => {
        if (response.status === 200) {
          this.getFoldersAndGroups()
        }
      })
      .catch(error => {
        console.log(error)
      })
    this.selected_groups = []
  }

  async updateNotify(item: any) {
    let data = {
      group_id: item.id,
      can_notify: item.can_notify
    }
    await GroupFolderService.updateNotificationForGroup(data)
    this.getFoldersAndGroups()
  }

  handleUnknownArray(unknownArray: any[]): any[] {
    return unknownArray
  }

  showFolderListOrder(emitDate: any) {
    this.selected = emitDate.folderName
    emitDate.openModal ? this.$bvModal.show('change-order') : ''
  }
}
