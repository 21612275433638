



































































































import { Prop, Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ModalEditFolder extends Vue {
  @Prop() folder!: any
  @Prop() folderNameKey!: string
  @Prop() isSetting?: boolean
  private currentName: string = ''
  private newName: string = ''
  private ableClose: boolean = false

  handlePreventEvent(event: any) {
    if (!this.ableClose && this.newName !== this.currentName) {
      event.preventDefault()
      this.confirmHideModal()
    }
  }

  created() {
    this.bindingName()
  }

  @Watch('folder.name')
  bindingName() {
    this.ableClose = false
    this.currentName = this.folder ? this.folder[this.folderNameKey] : ''
    this.newName = this.folder ? this.folder[this.folderNameKey] : ''
  }

  confirmHideModal() {
    if (this.newName === this.currentName) {
      this.hideModal()
    } else {
      this.$bvModal.show('edit-folder-modal-confirm')
    }
  }

  async hideModal() {
    await this.handleHide()
    this.ableClose = false
    this.$emit('selectedOrder', { folderName: '', openModal: false })
    this.bindingName()
  }

  async handleHide() {
    this.ableClose = true
    this.$bvModal.hide('edit-folder')
  }

  onSubmit() {
    this.$emit('submit', this.newName)
    this.hideModal()
  }

  onDelete() {
    this.$emit('delete')
    this.hideModal()
  }

  selectedOrder() {
    this.$emit('selectedOrder', {
      folderName: this.currentName,
      openModal: true
    })
    this.hideModal()
  }
  changeOrderFolder() {
    this.$emit('changeOrderFolder', this.folder)
    this.hideModal()
  }
}
